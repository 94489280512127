import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

function Awards() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <div
      style={{
        background: "transparent",
      }}
      className="section section-with-space"
      data-parallax={true}
      ref={pageHeader}
    >
      <Container>
        <Row>
          <Col lg={6} className="align-self-center">
            <img
              src={require(`assets/images/awardslogo.png`)}
              width="100%"
              style={{ maxWidth: "450px" }}
              alt="awards"
            />
          </Col>
          <Col lg={6} className="align-self-center px-3">
            <h4 className="text-white text-400">
              Finnovex Awards have been constituted to recognise and appreciate the effort of
              various individuals, institutions and service providers in the field of financial
              services. We seek to honour the pioneers and visionaries who have transformed the
              financial services industry.
            </h4>
            <h4 className="text-white text-400">
              <b>July 23, 2025</b>
            </h4>
            <Button
              className="px-3 py-3 mt-5 text-center"
              color="primary"
              size="lg"
              outline
              href="/awards"
            >
              <h3 className="m-0 text-700" style={{ color: "#fff" }}>
                <b>Nominate Now</b>
              </h3>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Awards;
