import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "reactstrap";
import Button from "reactstrap/lib/Button";
import S2 from "./svgs/s2";
import S5 from "./svgs/s5";
import S8 from "./svgs/s8";
import S1 from "./svgs/s1";
import S3 from "./svgs/s3";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const content = [
    {
      title: "Digital Banking and Transaction: Pioneering Financial Evolution",
      description:
        "In an era of digital transformation, 'Digital Banking and Payments' have emerged as critical elements in reshaping the financial landscape. Delve into the significant impact of digitalization on banking services and payment systems. We will explore the rapid evolution of online banking platforms, mobile payment solutions, and the growing influence of cryptocurrencies.",
      image: <S2 />,
    },
    {
      title: "Collaboration for Success:  Fintech-Bank Partnerships Driving Innovation",
      description:
        " Illuminates the transformative potential of collaborative endeavours between fintech and banking institutions. In an era defined by rapid technological advancement and evolving customer expectations, this session explores how strategic partnerships can catalyze innovation and drive meaningful change within the financial services industry.",
      image: <S3 />,
    },
    {
      title: "Cyber Resilience: Safeguarding Digital Trust in an Era of Threats",
      description:
        "In an interconnected world driven by digital innovation, the banking sector faces unprecedented cybersecurity challenges. delve into the evolving landscape of cybersecurity and explore strategies for banks to fortify their defenses, mitigate risks, and safeguard digital trust.",
      image: <S8 />,
    },
    {
      title: "Data Frameworks and Digital Compliance Leadership",
      description:
        "Data and regulators often play a crucial role by creating an enabling regulatory environment that encourages financial institutions to reach underserved populations. Explores the vital role of regulatory adherence and compliance leadership in the digital banking landscape. ",
      image: <S8 />,
    },
  ];

  return (
    <>
      <div
        style={{
          background: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className=" text-700 pb-0 text-white mt-0 text-uppercase"
                style={{ fontSize: size ? "2rem" : "1.5rem" }}
              >
                <b>Empowering the Financial Renaissance:</b>
              </h1>
              <h1
                className=" text-400 pb-0 text-white mt-0 text-uppercase"
                style={{ fontSize: size ? "2rem" : "1.5rem" }}
              >
                Leading the Digital Revolution, Embracing Inclusivity, Pioneering Sustainable
                Finance, and Driving Economic Digitalization to Victory.
              </h1>
            </Col>
            <Col lg={6} md={6} sm={12} className="">
              <h5
                className="text-400 text-white text-justify"
                style={{
                  lineHeight: "30px",
                  color: "#010011",
                  textTransform: "none",
                }}
              >
                <br />
                <br />
                In the heart of the digital revolution in Southern Africa, the financial industry
                stands at a crossroads, poised for profound transformation. Southern Africa is
                embracing the "Financial Symphony" by showcasing the power of collaboration between
                traditional financial institutions, fintech pioneers, government bodies, and
                international organisations. Witness how these partnerships accelerate progress
                toward financial inclusion, sustainability goals and economic digitalization, with
                the transformative power of blockchain and cryptocurrencies. The emergence of
                cutting-edge technologies, the relentless pursuit of financial inclusion, and the
                imperative of sustainable finance are driving this change. The highly anticipated
                Finnovex South Africa 2025 is a conference that will not only acknowledge these
                shifts but also pave the way for financial institutions to navigate and thrive in
                the digital era and beyond.
                <br />
                <br />
                <b style={{ fontSize: 25, color: "#770c2c" }}>
                  Transforming the Digital Renaissance Together{" "}
                </b>
                <br />
                <br />
                The "Digital Renaissance" signifies a rebirth—a moment when finance is being reborn
                in the digital realm. It's not just about adopting technology; it's about embracing
                a new way of thinking and operating. The Digital Renaissance is characterised by
                artificial intelligence, blockchain, open banking, and a customer-centric approach
                that transcends traditional boundaries. The 26th Edition of Finnovex Southern Africa
                is back to recognize and embrace these transformative shifts, while also serving as
                a guiding light for financial institutions seeking to not only adapt but flourish in
                this dynamic digital era. Join us as we chart a course towards a future where
                finance is reshaped and redefined to meet the needs of the modern world.
                <br />
                <br />
                <Button className="px-3 py-2  my-2 rounded-0" color="primary" href="/about">
                  <p className="m-0 text-700" style={{ color: "#fff" }}>
                    Know More
                  </p>
                </Button>
              </h5>
            </Col>
            <Col lg={6} className="mt-5 pr-0">
              {content.map((s, index) => (
                <ScrollAnimation
                  animateIn={"fadeInUp"}
                  animateOnce={true}
                  duration={0.5 + index / 8}
                >
                  <div className="py-4 px-2 stat-div mb-2" style={{ minHeight: 200 }}>
                    <div className="container-fluid row pr-0">
                      <Col className="align-self-center" xs={3}>
                        {s.image}
                      </Col>
                      <Col>
                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "18px" : "14px",
                          }}
                          className="text-700 mt-0"
                        >
                          {s.title}
                        </h3>
                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "16px" : "12px",
                          }}
                          className="text-400 mt-0"
                        >
                          {s.description}
                        </h3>
                      </Col>
                    </div>
                  </div>
                </ScrollAnimation>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
